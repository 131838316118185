/* eslint-disable no-restricted-syntax */
// @flow

/* eslint-disable quotes */
/* eslint-disable comma-dangle */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */

import React, { useEffect } from 'react';
import cn from 'classnames';
import Navigation from 'invite/components/Navigation.component';

import Button from 'components/Button.component';
import { useTheme } from 'providers/Theme.provider';

import StartAgainAndBackButtonsComponent from 'booking/common/StartAgainAndBackButtons.component';
import StylistItemComponent from 'booking/common/StylistItem';
import type { Service } from 'types/service';
import type { Stylist } from 'types/stylistsForService';

import CN from './StylistSelection.module.scss';

type Props = {
  services: {
    name: string,
    service_id: number,
    stylists: Stylist[],
  }[],
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  isWaitList: boolean,
  availableWaitListStylists: any,
  selectedServices: Service[],
  onCheckboxClick: (serviceId: number, stylist: Stylist) => void,
  book_ncob_with_same: boolean,
};

const sortAccordingToService = (obj: any[], selectedServices) => {
  const serviceIds = selectedServices.map(service => service.id);
  const newArr = [];
  for (let i = 0; i < serviceIds.length; i += 1) {
    newArr.push(obj.filter(e => e.service_id === serviceIds[i])[0]);
  }

  return newArr;
};

const StylistSelection = (props: Props) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const services = props.services.slice();
  const sorted = sortAccordingToService(services, props.selectedServices);
  const waitlistStylists = [];
  const waitlistServiceIdsDup = [];
  let waitlistServiceIds = [];
  const sortedWithWaitStylist = [];
  if (props.isWaitList) {
    /* eslint-disable prefer-destructuring */
    // waitlistStylists = [...props.availableWaitListStylists.map(s => (Object.values(s.data): any)[0].options)][0];
    const l = props.availableWaitListStylists;
    for (let i = 0; i < l.length; i += 1) {
      if (Object.keys(l[i].data).length > 0) {
        waitlistStylists.push(...(Object.values(l[i].data)[0]: any).options);
      }
    }
    props.availableWaitListStylists.forEach(s => {
      const ids = (Object.values(s.data): any)[0].options.map(service => service.service_id);
      waitlistServiceIdsDup.push(...ids);
    });
    const set = new Set(waitlistServiceIdsDup);
    waitlistServiceIds = [...set];
    for (let i = 0; i < waitlistServiceIds.length; i += 1) {
      const index = sorted.findIndex(s => s.service_id === waitlistServiceIds[i]);
      if (index >= 0) {
        sortedWithWaitStylist.push(sorted[index]);
      }
    }
  }
  // last one is stylist any so it's hidden, we need to click items above to revail it
  useEffect(() => {
    if (
      props.services[props.services.length - 1] &&
      props.services[props.services.length - 1].stylists[0] === undefined
    ) {
      if (Object.keys(props.selectedStylists).length === props.services.length - 1) {
        const interval = setInterval(() => {
          const styDivEl = document.querySelector('.the-service-shape.stylist');
          if (styDivEl) {
            clearInterval(interval);
            styDivEl.click();
          }
        });
      }
    }
  }, [props.services, props.selectedStylists]);

  const handleServiceClick = (serviceId, stylist) => {
    props.onCheckboxClick(serviceId, stylist);
  };

  const confirmStylistSelection = () => {
    Object.keys(props.selectedStylists).forEach(serviceId => {
      const selectedStylist = props.selectedStylists[serviceId];
      if (selectedStylist) {
        handleServiceClick(Number(serviceId), selectedStylist);
      }
    });
  };

  return (
    <div
      data-bem="StylistSelection__container"
      className={cn({
        'stylist-container': true,
        [CN.component]: true,
        [CN.neutral]: isNeutralMode,
        [CN.dark]: isDarkMode,
      })}
    >
      <br />
      <Navigation match={props.match} />
      <StartAgainAndBackButtonsComponent />

      {!props.isWaitList &&
        sorted.map((service, i) => {
          const variablePricing = props.selectedServices.filter(e => e.id === service.service_id)[0].variable_pricing;
          const filteredStylists =
            service.stylists && Array.isArray(service.stylists) ? service.stylists.filter(s => s !== undefined) : [];
          const hasStylists = filteredStylists.length > 0;
          const isFavourite = service.stylist ? service.stylist.favourite_or_any : '';

          return (
            <div className="cfix group-shape" key={`service${service.service_id}`}>
              {hasStylists && <span className={CN.title}>{`Select staff member for ${service.name}`}</span>}

              {hasStylists ? (
                <div className={CN.flex}>
                  {service.stylists.map((stylist, j) => {
                    if (stylist && stylist.service_id === service.service_id) {
                      let checked = false;
                      const selectedStylist = props.selectedStylists[stylist.service_id.toString()];
                      if (selectedStylist && selectedStylist.stylist_id === stylist.stylist_id) {
                        checked = true;
                      }

                      return (
                        <StylistItemComponent
                          checked={checked}
                          key={`stylist${stylist.stylist_id}-${service.service_id}`}
                          checkId={`cb${i}${j}`}
                          stylist={stylist}
                          service_id={service.service_id}
                          itemPriceCost={stylist.price}
                          onClick={() => handleServiceClick(service.service_id, stylist)}
                          favourite={isFavourite}
                          variable_pricing={variablePricing}
                          book_ncob_with_same={props.book_ncob_with_same}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              ) : (
                <div className={CN.buttonContainer}>
                  <div className={CN.buttonSpace}>
                    <Button
                      variant="primary"
                      onClick={() => {
                        confirmStylistSelection(service);
                      }}
                    >
                      <span className={CN.gapSm}>Select staff member for</span>
                    </Button>
                  </div>
                  <div className={CN.serviceName}>{service.name}</div>
                </div>
              )}
            </div>
          );
        })}

      {props.isWaitList &&
        sortedWithWaitStylist.map((service, i) => {
          const variablePricing = props.selectedServices.filter(e => e.id === service.service_id)[0].variable_pricing;
          const showStylist = waitlistServiceIds.indexOf(service.service_id) >= 0;
          return (
            <div
              className={`cfix group-shape ${showStylist ? '' : 'hide-stylist'}`}
              key={`service${service.service_id}`}
            >
              <span className="service-title">{`Staff for ${service.name}`}</span>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {waitlistStylists.map((stylist: any, j) => {
                  let checked = false;
                  const sty =
                    stylist && stylist.service_id ? props.selectedStylists[stylist.service_id.toString()] : '';
                  if (sty && sty.stylist_id === stylist.stylist_id) {
                    checked = true;
                  }
                  if (service.service_id !== stylist.service_id) {
                    return null;
                  }
                  const stylistKey = stylist ? stylist.stylist_id : 'any';
                  return (
                    <StylistItemComponent
                      checked={checked}
                      key={`stylist${stylistKey}-${stylist.service_id}`}
                      checkId={`cb${i}${j}`}
                      stylist={stylist}
                      service_id={stylist.service_id}
                      itemPriceCost={stylist.price}
                      onClick={() => props.handleServiceClick(service.service_id, stylist)}
                      favourite={stylist.favourite_or_any}
                      variable_pricing={variablePricing}
                      book_ncob_with_same={props.book_ncob_with_same}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default StylistSelection;
