/* eslint-disable react/no-danger */
// @flow

import React from 'react';
import AddToCalendar from 'react-add-to-calendar';
import { connect } from 'react-redux';
import cn from 'classnames';
import moment from 'moment-timezone';

import Button from 'components/Button.component';
import ExternalLink from 'components/ExternalLink.component';
import FoldOut from 'components/FoldOut.component';
import Footer from 'components/Footer.component';
import Toggler from 'components/Toggler.component';
import S from 'constants/string.const';
import { withTheme } from 'providers/Theme.provider';

import { updateNote, updateTotalPrice } from 'booking/actions';
import currency from 'booking/common/currency.util';
import SuccessfulPopup from 'booking/common/SuccessfulPopup';
import ServiceListItemComponent from 'booking/summary/ServiceLIstItem';
import BuyVoucherButton from 'booking/voucher/BuyVoucherButton.component';
import type { SalonDetails } from 'types/salonDetails';
import type { Service } from 'types/service';
import type { Stylist } from 'types/stylistsForService';

import './AddTocalendar.scss';
import CN from './Summary.module.scss';

const TWO_LINES_PX = 32;
const RENDER_OLD_POLICY = false;

type Props = {
  salonDetails: SalonDetails,
  selectedPatchTime: moment,
  selectedAppointmentTime: moment,
  selectedServices: Service[],
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  appointment_cost: number,
  isAppointmentConfirmed?: boolean,
  patchTestBookingResponse: { message: string },
  updateNote: (note: string) => void,
  updateTotalPrice: (price: string) => void,
  billing: any,
  history: any,
  isWaitList: boolean,
};

type State = {
  startTime: string,
  endTime: string,
  date: string,
  location: string,
  calendarEvent: {
    title: string,
    description: string,
    location: string,
    startTime: string,
    endTime: string,
  },
  calendarPatchEvent: {
    title: string,
    description: string,
    location: string,
    startTime: string,
    endTime: string,
  } | null,
};

export class Summary extends React.Component<Props, State> {
  static defaultProps = { isAppointmentConfirmed: false };

  constructor(props: Props) {
    super(props);
    this.textRef = React.createRef();
    const location = props.salonDetails.name;
    const calendarEvent = {
      title: `Appointment at ${props.salonDetails.name}`,
      description: '',
      location,
      startTime: '',
      endTime: '',
    };
    let calendarPatchEvent = null;
    if (props.selectedPatchTime) {
      calendarPatchEvent = {
        title: `Patch test at ${props.salonDetails.name}`,
        description: '',
        location,
        startTime: moment(props.selectedPatchTime).toISOString(),
        endTime: moment(props.selectedPatchTime)
          .add(5, 'minutes')
          .toISOString(),
      };
    }
    calendarEvent.startTime = props.selectedAppointmentTime.toISOString();
    const startTime = moment(props.selectedAppointmentTime)
      .tz(this.props.salonDetails.timezone)
      .format('HH:mm A');
    const endTimeMoment = moment(props.selectedAppointmentTime);
    Object.keys(props.selectedStylists).map(serviceId => {
      if (props.selectedStylists[serviceId] !== null) {
        endTimeMoment
          .add(moment.duration(props.selectedStylists[serviceId].duration))
          // flow is retarded
          // $FlowFixMe
          .add(moment.duration(props.selectedStylists[serviceId].processing));
      }
      return null;
    });

    calendarEvent.endTime = endTimeMoment.toISOString();
    const endTime = moment(endTimeMoment)
      .tz(this.props.salonDetails.timezone)
      .format('HH:mm A');
    const date = props.selectedAppointmentTime.format('dddd, D MMM YYYY');

    calendarEvent.location = location;
    let total = 0;
    const serviceNames: string[] = [];
    for (let i = 0; i < props.selectedServices.length; i += 1) {
      serviceNames.push(props.selectedServices[i].name);
      const stylist = props.selectedStylists[props.selectedServices[i].id.toString()];
      if (stylist !== null) {
        total += stylist.price;
      }
    }
    calendarEvent.description = `Services: ${serviceNames.join(', ')}; Price: £${total.toFixed(2)}`;
    this.props.updateTotalPrice(`£${total.toFixed(2)}`);

    this.state = {
      startTime,
      endTime,
      date,
      calendarEvent,
      calendarPatchEvent,
      showFullText: false,
      renderButtonViewMore: false,
      agreeWithTC: true,
    };
  }

  componentDidMount() {
    const clientHeight = this.textRef.current && this.textRef.current.clientHeight;

    if (clientHeight > TWO_LINES_PX) {
      this.toggleTextVisibility();
    }
  }

  onTextareaChange = (note: string) => {
    this.props.updateNote(note);
  };

  toggleTextVisibility = () => {
    const clientHeight = this.textRef.current && this.textRef.current.clientHeight;

    if (clientHeight > TWO_LINES_PX) {
      this.setState({ renderButtonViewMore: true });
    }
  };

  toggleText = () => {
    this.setState({
      showFullText: !this.state.showFullText,
    });
  };

  toggleAgree = () => {
    this.setState({
      agreeWithTC: !this.state.agreeWithTC,
    });
  };

  backToStart = () => {
    const { id } = this.props.salonDetails;
    if (id) {
      // prevent user click logout quickly after click this, made id undefined
      setTimeout(() => {
        this.props.history.push(`/salon/${id.toString()}/`);
      }, 500);
    }
  };

  expandedText = () => void this.setState({ expanded: !this.state.expanded });

  render() {
    const { salonDetails, isDarkMode, isNeutralMode } = this.props;
    const { showFullText, renderButtonViewMore } = this.state;
    const abbreviation = this.props.salonDetails.currency;

    let isVariablePrice = false;
    let depositLabel = '';
    let totalLabel = '';
    let moneyDue = 0;

    const isConfirmed = this.props.isAppointmentConfirmed;
    if (!isConfirmed && salonDetails.needs_card) {
      depositLabel = 'of which payable today:';
      totalLabel = 'Total due:';
      moneyDue = currency({
        value: this.props.billing.appointment_cost,
        abbreviation,
      });
    } else if (!isConfirmed && !salonDetails.needs_card) {
      depositLabel = '';
      totalLabel = 'Total due:';
      moneyDue = currency({
        value: this.props.billing.appointment_cost,
        abbreviation,
      });
    } else if (isConfirmed && salonDetails.needs_card) {
      depositLabel = 'Paid today:';
      totalLabel = 'Total due:';
      const calcTotal = this.props.billing.appointment_cost - this.props.billing.pay_today;
      moneyDue = currency({ value: calcTotal, abbreviation });
    } else if (isConfirmed && !salonDetails.needs_card) {
      depositLabel = '';
      totalLabel = 'Balance due in store:';
      moneyDue = currency({
        value: this.props.billing.appointment_cost,
        abbreviation,
      });
    }
    const payToday = !this.props.isWaitList ? this.props.billing.pay_today : 0;
    const hasVariablePricing = this.props.selectedServices.some(service => service.variable_pricing);

    const policyClass = cn({
      [CN.policy]: true,
      [CN.isOpen]: showFullText,
    });

    const { address_line_1: addressLine1, addressLine2, city, postcode } = salonDetails;
    const formatAddress = () => {
      let address = '';
      if (addressLine1) address += addressLine1;
      if (addressLine2) address += `, ${addressLine2}`;
      if (city) address += `, ${city}`;
      if (postcode) address += `, ${postcode}`;
      return address;
    };

    const address = formatAddress();

    const googleMapsLink = address
      ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`
      : null;

    return (
      <div
        data-bem="Summary"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        {!this.props.isAppointmentConfirmed && salonDetails.salon_ncob_text && (
          <div>
            <div className={CN.policyContainer}>
              <div className={CN.policyTitle}>
                <div className={CN.icon} />
                Cancellation Policy
              </div>
              <p
                className={policyClass}
                ref={this.textRef}
                dangerouslySetInnerHTML={{
                  __html: salonDetails.salon_ncob_text,
                }}
              />

              {renderButtonViewMore && (
                <button onClick={this.toggleText} className={CN.buttonMore}>
                  {showFullText ? 'view less' : '...view more'}
                </button>
              )}
            </div>

            <div className={CN.policyAgree}>
              <Toggler selected={this.state.agreeWithTC} onClick={this.toggleAgree} tag="Summary__toggler" />

              <div className={CN.agreeText}>
                I can confirm that I have read and understood the cancellation policy above
              </div>
            </div>
          </div>
        )}
        {RENDER_OLD_POLICY && (
          <div>
            {!this.props.isWaitList && this.props.isAppointmentConfirmed && salonDetails.salon_ncob_text && (
              <div className={CN.policyContainer}>
                <div className={CN.policyTitle}>
                  <div className={CN.icon} />
                  Cancellation Policy
                </div>
                <p
                  className={policyClass}
                  ref={this.textRef}
                  dangerouslySetInnerHTML={{
                    __html: salonDetails.salon_ncob_text,
                  }}
                />

                {renderButtonViewMore && (
                  <button onClick={this.toggleText} className={CN.buttonMore}>
                    {showFullText ? 'view less' : '...view more'}
                  </button>
                )}
              </div>
            )}
          </div>
        )}

        {this.props.isWaitList && this.props.isAppointmentConfirmed && (
          <div className={CN.gradientWaitlist}>
            Your waitlist request is now visible in your online profile - please tap ‘Bookings’ above to check the
            status of your request. Note: You will not be sent a confirmation message until your request has been
            confirmed.
          </div>
        )}
        <div className={CN.content}>
          {this.props.isAppointmentConfirmed && this.props.selectedPatchTime && (
            <SuccessfulPopup
              message={`Your patch test is scheduled for ${moment(this.props.selectedPatchTime)
                .tz(salonDetails.timezone)
                .format('LLLL')}`}
            />
          )}
          <div className={CN.subTitle}>
            <div className={CN.iconAppointment} />
            Appointment Details
          </div>
          <div className={CN.row}>
            <div>
              <div className={CN.boldText}>{this.state.date}</div>
              <span className={CN.textSecondary}> {`${this.state.startTime} - ${this.state.endTime}`}</span>
            </div>
          </div>
          <div className={CN.divider} />
          <div className={CN.servicesContainer}>
            {this.props.selectedServices.map(service => {
              const stylist = this.props.selectedStylists[service.id.toString()];
              if (service.variable_pricing && !isVariablePrice) {
                isVariablePrice = true;
              }
              if (stylist !== null) {
                return (
                  <ServiceListItemComponent
                    price={currency({ value: stylist.price, abbreviation })}
                    service={service}
                    stylist={stylist}
                    key={service.id}
                  />
                );
              }
              return false;
            })}
          </div>
          <div className={CN.divider} />
          <div className={CN.totalContainer}>
            {!this.props.isWaitList && (
              <div className={CN.row}>
                <div className={CN.boldText}> {totalLabel}</div>
                <div className={CN.rowValue}>
                  {this.props.selectedServices.map(service => (
                    <React.Fragment key={service.id}>
                      {service.variable_pricing === true ? <span className={CN.inline}>From {S.space}</span> : ' '}
                    </React.Fragment>
                  ))}
                  {moneyDue}
                </div>
              </div>
            )}

            {!this.props.isWaitList && depositLabel && (
              <div className={CN.row}>
                <div className={CN.boldText}> {depositLabel}</div>
                <div className={CN.rowValueTotal}>{currency({ value: payToday, abbreviation })}</div>
              </div>
            )}
          </div>
          <div className={CN.divider} />

          {hasVariablePricing && (
            <div className={CN.variablePricingNote}>*Price may vary if your appointment requires extra time</div>
          )}

          {!this.props.isAppointmentConfirmed && (
            <div>
              <div className={CN.noteContainer}>
                {this.props.isWaitList ? (
                  <div className={CN.noteTitle}>
                    Please specify below if you would like to see a specific staff member or if you have any flexibility
                    on appointment start time
                  </div>
                ) : (
                  <div className={CN.noteTitle}>Notes/discount codes:</div>
                )}

                <textarea
                  className={CN.textarea}
                  placeholder="Add a note or discount code (optional)"
                  onChange={e => {
                    this.onTextareaChange(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
        </div>

        {isConfirmed && (
          <div>
            <div className={CN.addressContainer} data-bem="Summary__foldOut">
              <FoldOut title="Address" icon="building">
                <div className={CN.addressText} data-bem="Summary__address--text">
                  {googleMapsLink ? (
                    <ExternalLink link={googleMapsLink}>
                      <div className={CN.link}>{addressLine1}</div>
                      <div className={CN.link}>{addressLine2}</div>
                      <div className={CN.link}>{city}</div>
                      <div className={CN.link}>{postcode}</div>
                    </ExternalLink>
                  ) : (
                    <>
                      <div>{addressLine1}</div>
                      <div>{addressLine2}</div>
                      <div>{city}</div>
                      <div>{postcode}</div>
                    </>
                  )}
                </div>
              </FoldOut>
            </div>
            {!this.props.isWaitList && (
              <div className={CN.policyContainer} data-bem="Summary__foldOut">
                <FoldOut title="Cancellation Policy" icon="policy">
                  <div className={CN.policyText} data-bem="Summary__policy--text">
                    {salonDetails.salon_ncob_text}
                  </div>
                </FoldOut>
              </div>
            )}
            <div data-bem="Summary__BuyVoucherButton">
              <BuyVoucherButton />
            </div>
          </div>
        )}

        {isConfirmed && (
          <Footer data-bem="Summary__footer">
            <div className="add-to-calendar">
              {!this.props.isWaitList && (
                <Button variant="secondary" width="fixed">
                  <AddToCalendar event={this.state.calendarEvent} buttonLabel="ADD TO CALENDAR" />
                </Button>
              )}
              {!this.props.isWaitList && this.props.selectedPatchTime && this.props.patchTestBookingResponse && (
                <Button variant="secondary" width="fixed">
                  <AddToCalendar event={this.state.calendarPatchEvent} buttonLabel="Add patch test to calendar" />
                </Button>
              )}
            </div>
            <Button variant="primary" width="fixed" onClick={this.backToStart}>
              BOOK ANOTHER APPOINTMENT
            </Button>
          </Footer>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { booking, billing } = state;
  const {
    salonDetails,
    selectedPatchTime,
    selectedAppointmentTime,
    selectedStylists,
    selectedServices,
    note,
    patchTestBookingResponse,
    isWaitList,
  } = booking;
  return {
    salonDetails,
    selectedPatchTime,
    selectedAppointmentTime,
    selectedStylists,
    selectedServices,
    note,
    patchTestBookingResponse,
    billing,
    isWaitList,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  updateNote(note: string) {
    dispatch(updateNote(note));
  },
  updateTotalPrice(price: string) {
    dispatch(updateTotalPrice(price));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(Summary));
